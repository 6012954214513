<template>
  <GamesList title="Wild Cards" />
</template>

<script>
import GamesList from "@/components/GamesList.vue";

export default {
  components: { GamesList },
  metaInfo: {
    title: "Wild Cards | Lucky Won",
  },
};
</script>
